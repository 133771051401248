import createMutationsSharer from "vuex-shared-mutations";

import {type} from "./mutations";

export const plugins = [

  createMutationsSharer({
    predicate: [
      type.INTERNAL_CHAT_SET_VISIBLE,
      type.USER_SET_IDLE_TIMEOUT,
      type.LOGOUT,
    ]
  }),

];