import { type } from "./mutations";

import UserService from "../services/user.service";

const userService = new UserService();

export const actions = {
  async login({ commit }, credentials) {
    commit(type.LOGIN);

    try {
      const res = await userService.login(credentials.username, credentials.password, credentials.recaptcha);

      if ( res && !res.error ) {
        let expires = new Date();
        expires.setHours(expires.getHours() + 12);
        expires.setMinutes(expires.getMinutes() - 1);

        localStorage.setItem("token", res.token);
        localStorage.setItem("hash", res.hash);
        localStorage.setItem("expires", expires.getTime().toString());
        localStorage.setItem("user", JSON.stringify(res.user));
        localStorage.removeItem("impersonate");

        commit(type.LOGIN_SUCCESS);
        commit(type.SET_USER, res.user);
        return { status: true, message: res.message };

      } else {
        return { status: false, message: res.message };
      }

    } catch (e) {
      return { status: false, message: e };
    }
  },

  logout({ commit }) {
    try {
      const user = JSON.parse(localStorage.getItem('user'));

      userService.logout(user._id);

      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("hash");
      localStorage.removeItem("expires");
      localStorage.removeItem("impersonate");

      commit(type.LOGOUT);

    } catch (e) {
      return false;
    }
  },

  async impersonate({ commit }, impersonate) {
    try {
      commit(type.LOGIN_SUCCESS)
      let { data } = impersonate.res;
      //if(data.hasOwnProperty('impersonate')) {
      if ( Object.prototype.hasOwnProperty.call(data, 'impersonate') ) {
        let expires = new Date();
        expires.setHours(expires.getHours() + 24);
        expires.setMinutes(expires.getMinutes() - 1);
        localStorage.setItem("token", data.token);
        localStorage.setItem("hash", data.hash);
        localStorage.setItem("impersonate", data.impersonate);
        localStorage.setItem("expires", expires.getTime().toString());
        localStorage.setItem("user", JSON.stringify(data.user));
        return true;
      } else {
        let expires = new Date();
        localStorage.removeItem("impersonate");
        expires.setHours(expires.getHours() + 24);
        expires.setMinutes(expires.getMinutes() - 1);
        localStorage.setItem("token", data.token);
        localStorage.setItem("hash", data.hash);
        localStorage.setItem("expires", expires.getTime().toString());
        localStorage.setItem("user", JSON.stringify(data.user));
        return true;
      }
    } catch (error) {
      return false;
    }
  },

  socket_message: ({ commit, dispatch }, message) => {
    dispatch('NEW_MESSAGE', message);
    commit('NEW_MESSAGE', message);
  },

  setUser({ commit }, user) {
    commit(type.SET_USER, user);
  },
  setUserIdleTimeout({ commit }, seconds = 300) {
    commit(type.USER_SET_IDLE_TIMEOUT, seconds);
  },

  setIdle({ commit }, value) {
    commit(type.SET_IDLE, value);
  },

  setIdleShared({ commit }, value) {
    commit(type.SET_IDLE_SHARED, value);
  },

  addNotification({ commit }, notification) {
    commit(type.NOTIFICATIONS_ADD, notification);
  },
  setNotifications({ commit }, notifications) {
    commit(type.NOTIFICATIONS_SET, notifications);
  },
  delNotification({ commit }, notification) {
    commit(type.NOTIFICATIONS_DELETE, notification);
  },
  readNotification({ commit }, objType) {
    commit(type.NOTIFICATIONS_READ, objType);
  },
  clearNotification({ commit }) {
    commit(type.NOTIFICATIONS_CLEAR);
  },

  addTransferRequest({ commit }, request) {
    commit(type.TRANSFER_REQUEST_ADD, request);
  },
  setTransferRequests({ commit }, requests) {
    commit(type.TRANSFER_REQUEST_SET, requests);
  },
  delTransferRequest({ commit }, index) {
    commit(type.TRANSFER_REQUEST_DELETE, index);
  },
  clearTransferRequests({ commit }) {
    commit(type.TRANSFER_REQUEST_CLEAR);
  },

  addChatRequest({ commit }, request) {
    commit(type.CHAT_REQUEST_ADD, request);
  },
  setChatRequests({ commit }, requests) {
    commit(type.CHAT_REQUEST_SET, requests);
  },
  delChatRequest({ commit }, request) {
    commit(type.CHAT_REQUEST_DELETE, request);
  },
  clearChatRequest({ commit }) {
    commit(type.CHAT_REQUEST_CLEAR);
  },

  addChatBotRequest({ commit }, request) {
    commit(type.CHAT_BOT_REQUEST_ADD, request);
  },
  setChatBotRequests({ commit }, requests) {
    commit(type.CHAT_BOT_REQUEST_SET, requests);
  },
  delChatBotRequest({ commit }, request) {
    commit(type.CHAT_BOT_REQUEST_DELETE, request);
  },
  clearChatBotRequests({ commit }) {
    commit(type.CHAT_BOT_REQUEST_CLEAR);
  },

  addSmsRequest({ commit }, request) {
    commit(type.SMS_REQUEST_ADD, request);
  },
  setSmsRequests({ commit }, requests) {
    commit(type.SMS_REQUEST_SET, requests);
  },
  delSmsRequest({ commit }, request) {
    commit(type.SMS_REQUEST_DELETE, request);
  },
  clearSmsRequest({ commit }) {
    commit(type.SMS_REQUEST_CLEAR);
  },

  addWorkPlanNotification({ commit }, notification) {
    commit(type.NEW_WORK_PLAN, notification);
  },
  delWorkPlanNotification({ commit }, notification) {
    commit(type.DELETE_WORK_PLAN, notification);
  },

  addCustomerNotification({ commit }, notification) {
    commit(type.CUSTOMER_NOTIFICATION_ADD, notification);
  },
  setCustomerNotifications({ commit }, notifications) {
    commit(type.CUSTOMER_NOTIFICATION_SET, notifications);
  },
  delCustomerNotification({ commit }, notification) {
    commit(type.CUSTOMER_NOTIFICATION_DELETE, notification);
  },
  clearCustomerNotifications({ commit }) {
    commit(type.CUSTOMER_NOTIFICATION_CLEAR);
  },

  addNewLead({ commit }, lead) {
    commit(type.NEW_LEAD_ADD, lead);
  },
  setNewLeads({ commit }, leads) {
    commit(type.NEW_LEAD_SET, leads);
  },
  delNewLead({ commit }, lead) {
    commit(type.NEW_LEAD_DELETE, lead);
  },
  clearNewLeads({ commit }) {
    commit(type.NEW_LEAD_CLEAR);
  },

  addMention({ commit }, mention) {
    commit(type.MENTION_ADD, mention);
  },
  setMentions({ commit }, mentions) {
    commit(type.MENTION_SET, mentions);
  },
  delMention({ commit }, mention) {
    commit(type.MENTION_DELETE, mention);
  },
  clearMentions({ commit }) {
    commit(type.MENTION_CLEAR);
  },

  addApptReminder({ commit }, appt) {
    commit(type.APPT_REMINDER_ADD, appt);
  },
  setApptReminder({ commit }, appts) {
    commit(type.APPT_REMINDER_SET, appts);
  },
  delApptReminder({ commit }, appt) {
    commit(type.APPT_REMINDER_DELETE, appt);
  },
  clearApptReminder({ commit }) {
    commit(type.APPT_REMINDER_CLEAR);
  },

  addFinanceApplication({ commit }, finance) {
    commit(type.FINANCE_APPLICATION_ADD, finance);
  },
  setFinanceApplication({ commit }, finances) {
    commit(type.FINANCE_APPLICATION_SET, finances);
  },
  delFinanceApplication({ commit }, finance) {
    commit(type.FINANCE_APPLICATION_DELETE, finance);
  },
  clearFinanceApplication({ commit }) {
    commit(type.FINANCE_APPLICATION_CLEAR);
  },

  setPollEmailUid({ commit }, uid) {
    commit(type.SET_POLL_EMAIL, uid);
  },

  setCompanySettings({ commit }, companySettings) {
    commit(type.COMPANY_SETTINGS_SET, companySettings);
  },
  setCompanyPermissions({ commit }, permissions) {
    commit(type.SET_COMPANY_PERMISSIONS, permissions);
  },

  addCallRequest({ commit }, request) {
    commit(type.CALL_REQUEST_ADD, request);
  },
  setCallRequests({ commit }, requests) {
    commit(type.CALL_REQUEST_SET, requests);
  },
  delCallRequest({ commit }, request) {
    commit(type.CALL_REQUEST_DELETE, request);
  },
  clearCallRequest({ commit }) {
    commit(type.CALL_REQUEST_CLEAR);
  },

  addHoldCallRequest({ commit }, request) {
    commit(type.HOLD_CALL_REQUEST_ADD, request);
  },
  delHoldCallRequest({ commit }, request) {
    commit(type.HOLD_CALL_REQUEST_DELETE, request);
  },

  addQueueCall({ commit }, queue) {
    commit(type.CALL_QUEUE_ADD, queue);
  },
  shiftQueueCall({ commit }, queueId) {
    commit(type.CALL_QUEUE_SHIFT, queueId);
  },

  setQueueCall({ commit }, queue) {
    commit(type.CALL_QUEUE_SET, queue);
  },
  updateQueueCall({ commit }, queue) {
    commit(type.CALL_QUEUE_UPDATE, queue);
  },
  delQueueCall({ commit }, queue) {
    commit(type.CALL_QUEUE_DELETE, queue);
  },
  clearQueueCall({ commit }) {
    commit(type.CALL_QUEUE_CLEAR);
  },

  addLoading({ commit }, value) {
    commit(type.LOADING_ADD, value);
  },
  delLoading({ commit }, value) {
    commit(type.LOADING_DELETE, value);
  },
  clearLoading({ commit }) {
    commit(type.LOADING_CLEAR);
  },

  setCallTime({ commit }, callTime) {
    commit(type.SET_CALL_TIME, callTime);
  },
  setCallSid({ commit }, callSid) {
    commit(type.SET_CALL_SID, callSid);
  },
  startCall({ commit }, callInfo) {
    commit(type.CALL_START, callInfo);
  },
  endCall({ commit }) {
    commit(type.CALL_END);
  },
  setCallConnection({ commit }, callConnection) {
    commit(type.SET_CALL_CONNECTION, callConnection);
  },

  addChatGroup({ commit }, { group }) {
    commit(type.CHAT_GROUP_ADD, group);
  },
  setChatGroups({ commit }, groups) {
    commit(type.CHAT_GROUP_SET, groups);
  },
  updateChatGroup({ commit }, data) {
    commit(type.CHAT_GROUP_UPDATE, data);
  },
  setNotReadChatGroup({ commit }, data) {
    commit(type.CHAT_GROUP_SET_NOT_READ, data);
  },
  setOnlineChatGroup({ commit }, group) {
    commit(type.CHAT_GROUP_SET_ONLINE, group);
  },
  setOfflineChatGroup({ commit }, group) {
    commit(type.CHAT_GROUP_SET_OFFLINE, group);
  },
  delChatGroup({ commit }, id) {
    commit(type.CHAT_GROUP_DEL, id);
  },
  clearChatGroup({ commit }) {
    commit(type.CHAT_GROUP_CLEAR);
  },

  addArchivedChatGroup({ commit }, { group }) {
    commit(type.ARCHIVED_CHAT_GROUP_ADD, group);
  },
  setArchivedChatGroups({ commit }, groups) {
    commit(type.ARCHIVED_CHAT_GROUPs_SET, groups);
  },
  updateArchivedChatGroup({ commit }, data) {
    commit(type.ARCHIVED_CHAT_GROUP_UPDATE, data);
  },
  setNotReadArchivedChatGroup({ commit }, data) {
    commit(type.ARCHIVED_CHAT_GROUP_SET_NOT_READ, data);
  },
  setOnlineArchivedChatGroup({ commit }, group) {
    commit(type.ARCHIVED_CHAT_GROUP_SET_ONLINE, group);
  },
  setOfflineArchivedChatGroup({ commit }, group) {
    commit(type.ARCHIVED_CHAT_GROUP_SET_OFFLINE, group);
  },
  delArchivedChatGroup({ commit }, id) {
    commit(type.ARCHIVED_CHAT_GROUP_DEL, id);
  },
  clearArchivedChatGroup({ commit }) {
    commit(type.ARCHIVED_CHAT_GROUP_CLEAR);
  },

  addOptoutChatGroup({ commit }, { group }) {
    commit(type.OPTOUT_CHAT_GROUP_ADD, group);
  },
  clearOptoutChatGroup({ commit }) {
    commit(type.OPTOUT_CHAT_GROUP_CLEAR);
  },
  delOptoutChatGroup({ commit }, id) {
    commit(type.OPTOUT_CHAT_GROUP_DEL, id);
  },
  setNotReadOptoutChatGroup({ commit }, data) {
    commit(type.OPTOUT_CHAT_GROUP_SET_NOT_READ, data);
  },

  addBotChatGroup({ commit }, { group }) {
    commit(type.BOT_CHAT_GROUP_ADD, group);
  },
  clearBotChatGroup({ commit }) {
    commit(type.BOT_CHAT_GROUP_CLEAR);
  },
  delBotChatGroup({ commit }, id) {
    commit(type.BOT_CHAT_GROUP_DEL, id);
  },
  setNotReadBotChatGroup({ commit }, data) {
    commit(type.BOT_CHAT_GROUP_SET_NOT_READ, data);
  },

  setRouteTitle({ commit }, title) {
    commit(type.SET_ROUTE_TITLE, title);
  },

  setImpersonate({ commit }, data) {
    commit(type.IMPERSONATE, data);
  },

  setUploading({ commit }, data) {
    commit(type.SET_UPLOADING, data);
  },

  setDownloading({ commit }, data) {
    commit(type.SET_DOWNLOADING, data);
  },

  setUploadImportFileData({ commit }, data) {
    commit(type.SET_UPLOAD_IMPORT_FILE_DATA, data);
  },

  setUploadImportFileStep({ commit }, data) {
    commit(type.SET_UPLOAD_IMPORT_STEP, data);
  },

  setSettingsLinks({ commit }, data) {
    commit(type.SET_SETTINGS_LINKS, data);
  },

  setCarPaginationDetails({ commit }, data) {
    commit(type.SET_CAR_PAGINATION_DETAILS, data);
  },

  setInternalChatVisible({ commit }, value) {
    commit(type.INTERNAL_CHAT_SET_VISIBLE, value);
  },
  setInternalChatUnreads({ commit }, { dm, group }) {
    commit(type.INTERNAL_CHAT_SET_UNREADS, { dm, group });
  },
  incInternalChatUnread({ commit }, messageType = '') {
    commit(type.INTERNAL_CHAT_SET_VISIBLE, messageType);
  },
  addInternalChatMessage({ commit }, message) {
    commit(type.INTERNAL_CHAT_ADD_MESSAGE, message);
  },
  setInternalChatMessages({ commit }, messages) {
    commit(type.INTERNAL_CHAT_SET_MESSAGES, messages);
  },

  setUserLockedCustomers({ commit }, lockedCustomers) {
    commit(type.USER_LOCKED_CUSTOMERS_SET, lockedCustomers);
  },
  cleanUserLockedCustomers({ commit }) {
    commit(type.USER_LOCKED_CUSTOMERS_CLEAN);
  },
};