import {initialState} from "@/store/state";
import {increaseTime} from './helpers';
import {Timer} from '@/helpers/timer';

const timer = new Timer();

export const type = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT: "LOGOUT",

  SET_USER: "SET_USER",
  USER_SET_IDLE_TIMEOUT: "USER_SET_IDLE_TIMEOUT",

  SET_IDLE: "SET_IDLE",
  SET_IDLE_SHARED: "SET_IDLE_SHARED",

  INTERNAL_CHAT_SET_VISIBLE: "INTERNAL_CHAT_SET_VISIBLE",
  INTERNAL_CHAT_INC_UNREAD: "INTERNAL_CHAT_INC_UNREAD",
  INTERNAL_CHAT_SET_UNREADS: "INTERNAL_CHAT_SET_UNREADS",
  INTERNAL_CHAT_ADD_MESSAGE: "INTERNAL_CHAT_ADD_MESSAGE",
  INTERNAL_CHAT_SET_MESSAGES: "INTERNAL_CHAT_SET_MESSAGES",

  SOCKET_CONNECT: "SOCKET_CONNECT",
  SOCKET_DISCONNECT: "SOCKET_DISCONNECT",
  SOCKET_MESSAGE: "SOCKET_MESSAGE",

  COMPANY_SETTINGS_SET: "COMPANY_SETTINGS_SET",
  SET_COMPANY_PERMISSIONS: "SET_COMPANY_PERMISSIONS",

  LOADING_ADD: "LOADING_ADD",
  LOADING_HAS: "LOADING_HAS",
  LOADING_DELETE: "LOADING_DELETE",
  LOADING_CLEAR: "LOADING_CLEAR",

  NOTIFICATIONS_ADD: "NOTIFICATIONS_ADD",
  NOTIFICATIONS_SET: "NOTIFICATIONS_SET",
  NOTIFICATIONS_DELETE: "NOTIFICATIONS_DELETE",
  NOTIFICATIONS_READ: "NOTIFICATIONS_READ",
  NOTIFICATIONS_CLEAR: "NOTIFICATIONS_CLEAR",

  CHAT_REQUEST_ADD: "CHAT_REQUEST_ADD",
  CHAT_REQUEST_SET: "CHAT_REQUEST_SET",
  CHAT_REQUEST_DELETE: "CHAT_REQUEST_DELETE",
  CHAT_REQUEST_CLEAR: "CHAT_REQUEST_CLEAR",

  CHAT_BOT_REQUEST_ADD: "CHAT_BOT_REQUEST_ADD",
  CHAT_BOT_REQUEST_SET: "CHAT_BOT_REQUEST_SET",
  CHAT_BOT_REQUEST_DELETE: "CHAT_BOT_REQUEST_DELETE",
  CHAT_BOT_REQUEST_CLEAR: "CHAT_BOT_REQUEST_CLEAR",

  SMS_REQUEST_ADD: "SMS_REQUEST_ADD",
  SMS_REQUEST_SET: "SMS_REQUEST_SET",
  SMS_REQUEST_DELETE: "SMS_REQUEST_DELETE",
  SMS_REQUEST_CLEAR: "SMS_REQUEST_CLEAR",

  TRANSFER_REQUEST_ADD: "TRANSFER_REQUEST_ADD",
  TRANSFER_REQUEST_SET: "TRANSFER_REQUEST_SET",
  TRANSFER_REQUEST_DELETE: "TRANSFER_REQUEST_DELETE",
  TRANSFER_REQUEST_CLEAR: "TRANSFER_REQUEST_CLEAR",

  CALL_REQUEST_ADD: "CALL_REQUEST_ADD",
  CALL_REQUEST_SET: "CALL_REQUEST_SET",
  CALL_REQUEST_DELETE: "CALL_REQUEST_DELETE",
  CALL_REQUEST_CLEAR: "CALL_REQUEST_CLEAR",
  CALL_REQUEST_HOLD: "CALL_REQUEST_HOLD",

  HOLD_CALL_REQUEST_ADD: "HOLD_CALL_REQUEST_ADD",
  HOLD_CALL_REQUEST_DELETE: "HOLD_CALL_REQUEST_DELETE",

  CALL_QUEUE_ADD: "CALL_QUEUE_ADD",
  CALL_QUEUE_SHIFT: "CALL_QUEUE_SHIFT",
  CALL_QUEUE_SET: "CALL_QUEUE_SET",
  CALL_QUEUE_DELETE: "CALL_QUEUE_DELETE",
  CALL_QUEUE_UPDATE: "CALL_QUEUE_UPDATE",
  CALL_QUEUE_CLEAR: "CALL_QUEUE_CLEAR",

  CUSTOMER_NOTIFICATION_ADD: "CUSTOMER_NOTIFICATION_ADD",
  CUSTOMER_NOTIFICATION_SET: "CUSTOMER_NOTIFICATION_SET",
  CUSTOMER_NOTIFICATION_DELETE: "CUSTOMER_NOTIFICATION_DELETE",
  CUSTOMER_NOTIFICATION_CLEAR: "CUSTOMER_NOTIFICATION_CLEAR",

  NEW_WORK_PLAN: "NEW_WORK_PLAN",
  DELETE_WORK_PLAN: "DELETE_WORK_PLAN",

  NEW_LEAD_ADD: "NEW_LEAD_ADD",
  NEW_LEAD_SET: "NEW_LEAD_SET",
  NEW_LEAD_DELETE: "NEW_LEAD_DELETE",
  NEW_LEAD_CLEAR: "NEW_LEAD_CLEAR",

  MENTION_ADD: "MENTION_ADD",
  MENTION_SET: "MENTION_SET",
  MENTION_DELETE: "MENTION_DELETE",
  MENTION_CLEAR: "MENTION_CLEAR",

  APPT_REMINDER_ADD: "APPT_REMINDER_ADD",
  APPT_REMINDER_SET: "APPT_REMINDER_SET",
  APPT_REMINDER_DELETE: "APPT_REMINDER_DELETE",
  APPT_REMINDER_CLEAR: "APPT_REMINDER_CLEAR",

  FINANCE_APPLICATION_ADD: "FINANCE_APPLICATION_ADD",
  FINANCE_APPLICATION_SET: "FINANCE_APPLICATION_SET",
  FINANCE_APPLICATION_DELETE: "FINANCE_APPLICATION_DELETE",
  FINANCE_APPLICATION_CLEAR: "FINANCE_APPLICATION_CLEAR",

  SET_POLL_EMAIL: "SET_POLL_EMAIL",

  SET_CALL_TIME: "SET_CALL_TIME",
  CALL_START: "CALL_START",
  CALL_END: "CALL_END",
  SET_CALL_SID: "SET_CALL_SID",
  SET_CALL_CONNECTION: "SET_CALL_CONNECTION",

  CHAT_GROUP_ADD: "CHAT_GROUP_ADD",
  CHAT_GROUP_SET: "CHAT_GROUP_SET",
  CHAT_GROUP_UPDATE: "CHAT_GROUP_UPDATE",
  CHAT_GROUP_SET_NOT_READ: "CHAT_GROUP_SET_NOT_READ",
  CHAT_GROUP_SET_ONLINE: "CHAT_GROUP_SET_ONLINE",
  CHAT_GROUP_SET_OFFLINE: "CHAT_GROUP_SET_OFFLINE",
  CHAT_GROUP_DEL: "CHAT_GROUP_DEL",
  CHAT_GROUP_CLEAR: "CHAT_GROUP_CLEAR",

  ARCHIVED_CHAT_GROUP_ADD: "ARCHIVED_CHAT_GROUP_ADD",
  ARCHIVED_CHAT_GROUPS_SET: "ARCHIVED_CHAT_GROUPS_SET",
  ARCHIVED_CHAT_GROUP_UPDATE: "ARCHIVED_CHAT_GROUP_UPDATE",
  ARCHIVED_CHAT_GROUP_SET_NOT_READ: "ARCHIVED_CHAT_GROUP_SET_NOT_READ",
  ARCHIVED_CHAT_GROUP_SET_ONLINE: "ARCHIVED_CHAT_GROUP_SET_ONLINE",
  ARCHIVED_CHAT_GROUP_SET_OFFLINE: "ARCHIVED_CHAT_GROUP_SET_OFFLINE",
  ARCHIVED_CHAT_GROUP_DEL: "ARCHIVED_CHAT_GROUP_DEL",
  ARCHIVED_CHAT_GROUP_CLEAR: "ARCHIVED_CHAT_GROUP_CLEAR",

  OPTOUT_CHAT_GROUP_ADD: "OPTOUT_CHAT_GROUP_ADD",
  OPTOUT_CHAT_GROUP_DEL: "OPTOUT_CHAT_GROUP_DEL",
  OPTOUT_CHAT_GROUP_CLEAR: "OPTOUT_CHAT_GROUP_CLEAR",
  OPTOUT_CHAT_GROUP_SET_NOT_READ: "OPTOUT_CHAT_GROUP_SET_NOT_READ",

  BOT_CHAT_GROUP_ADD: "BOT_CHAT_GROUP_ADD",
  BOT_CHAT_GROUP_DEL: "BOT_CHAT_GROUP_DEL",
  BOT_CHAT_GROUP_CLEAR: "BOT_CHAT_GROUP_CLEAR",
  BOT_CHAT_GROUP_SET_NOT_READ: "BOT_CHAT_GROUP_SET_NOT_READ",

  SET_ROUTE_TITLE: "SET_ROUTE_TITLE",

  SET_UPLOADING: "SET_UPLOADING",
  SET_DOWNLOADING: "SET_DOWNLOADING",

  SET_UPLOAD_IMPORT_FILE_DATA: "SET_UPLOAD_IMPORT_FILE_DATA",
  SET_UPLOAD_IMPORT_STEP: "SET_UPLOAD_IMPORT_STEP",

  SET_SETTINGS_LINKS: "SET_SETTINGS_LINKS",
  SET_CAR_PAGINATION_DETAILS: "SET_CAR_PAGINATION_DETAILS",

  USER_LOCKED_CUSTOMERS_SET: "USER_LOCKED_CUSTOMERS_SET",
  USER_LOCKED_CUSTOMERS_CLEAN: "USER_LOCKED_CUSTOMERS_CLEAN",
};

export const mutations = {
  [type.LOGIN](state) {
    state.pending = true;
  },
  [type.LOGIN_SUCCESS](state) {
    state.isLoggedIn = true;
    state.pending = false;
  },
  [type.LOGOUT](state) {
    Object.assign(state, initialState);
  },

  [type.SET_USER](state, user) {
    state.user = user;
  },
  [type.USER_SET_IDLE_TIMEOUT](state, seconds) {
    state.userIsIdle = false;
    timer.start(seconds).then(() => { state.userIsIdle = true });
  },

  [type.SOCKET_CONNECT](state) {
    state.isConnected = true;
  },
  [type.SOCKET_DISCONNECT](state) {
    state.isConnected = false;
  },
  [type.SOCKET_MESSAGE](state, message) {
    state.socketMessage = message
  },

  [type.NOTIFICATIONS_ADD](state, notification) {
    const index = state.unreadNotifications.findIndex(l => l._id === notification._id);
    if (index === -1) {
      state.unreadNotifications.unshift(notification);
    }
  },
  [type.NOTIFICATIONS_SET](state, notifications) {
    state.unreadNotifications = notifications;
  },
  [type.NOTIFICATIONS_DELETE](state, notification) {
    const index = state.unreadNotifications.findIndex(l => l._id === notification._id);
    if (index > -1) {
      state.unreadNotifications.splice(index, 1);
    }
  },
  [type.NOTIFICATIONS_READ](state, objType) {
    let copy = [];

    if (objType) {
      for (let i = 0; i < state.unreadNotifications.length; i++) {
        if (state.unreadNotifications[i].Type !== objType) {
          copy.push(state.unreadNotifications[i]);
        }
      }
      state.unreadNotifications = copy;
    } else {
      state.unreadNotifications = [];
    }
  },
  [type.NOTIFICATIONS_CLEAR](state) {
    state.unreadNotifications = [];
  },

  [type.CHAT_REQUEST_ADD](state, sid) {
    const index = state.chatRequests.findIndex(el => el === sid);
    if (index === -1) {
      state.chatRequests.unshift(sid);
    }
  },
  [type.CHAT_REQUEST_SET](state, chatRequests) {
    state.chatRequests = chatRequests;
  },
  [type.CHAT_REQUEST_DELETE](state, sid) {
    const index = state.chatRequests.indexOf(sid);
    if (index > -1) {
      state.chatRequests.splice(index, 1);
    }
  },
  [type.CHAT_REQUEST_CLEAR](state) {
    state.chatRequests = [];
  },

  [type.CHAT_BOT_REQUEST_ADD](state, customer) {
    const index = state.chatBotRequests.findIndex(el => el._id === customer._id);
    if (index === -1) {
      state.chatBotRequests.unshift(customer);
    }
  },
  [type.CHAT_BOT_REQUEST_SET](state, chatBotRequests) {
    state.chatBotRequests = chatBotRequests;
  },
  [type.CHAT_BOT_REQUEST_DELETE](state, sid) {
    const index = state.chatBotRequests.findIndex(el =>
        el.SID === sid || el.ChatClient === sid
    )
    if (index > -1) {
      state.chatBotRequests.splice(index, 1);
    }
  },
  [type.CHAT_BOT_REQUEST_CLEAR](state) {
    state.chatBotRequests = [];
  },

  [type.SMS_REQUEST_ADD](state, sms) {
    const index = state.smsRequests.findIndex(el => el._id === sms._id);
    if (index === -1) {
      state.smsRequests.unshift(sms);
    }
  },
  [type.SMS_REQUEST_SET](state, smsRequests) {
    state.smsRequests = smsRequests;
  },
  [type.SMS_REQUEST_DELETE](state, sms) {
    const index = state.smsRequests.findIndex(el => el._id === sms._id);
    if (index > -1) {
      state.smsRequests.splice(index, 1);
    }
  },
  [type.SMS_REQUEST_CLEAR](state) {
    state.smsRequests = [];
  },

  [type.TRANSFER_REQUEST_ADD](state, request) {
    const index = state.transferRequests.findIndex(l => l._id === request._id);
    if (index === -1) {
      state.transferRequests.unshift(request);
    }
  },
  [type.TRANSFER_REQUEST_SET](state, transferRequests) {
    state.chatRequests = transferRequests;
  },
  [type.TRANSFER_REQUEST_DELETE](state, transfer) {
    const index = state.transferRequests.findIndex(t => t._id === transfer);
    if (index > -1) {
      state.transferRequests.splice(index, 1);
    }
  },
  [type.TRANSFER_REQUEST_CLEAR](state) {
    state.transferRequests = [];
  },

  [type.CUSTOMER_NOTIFICATION_ADD](state, notification) {
    const index = state.customerNotifications.findIndex(l => l._id === notification._id);
    if (index === -1) {
      state.customerNotifications.unshift(notification);
    }
  },
  [type.CUSTOMER_NOTIFICATION_SET](state, notifications) {
    state.customerNotifications = notifications;
  },
  [type.CUSTOMER_NOTIFICATION_DELETE](state, notification) {
    const index = state.customerNotifications.findIndex(n => n._id === notification._id);
    if (index > -1) {
      state.customerNotifications.splice(index, 1);
    }
  },
  [type.CUSTOMER_NOTIFICATION_CLEAR](state) {
    state.customerNotifications = [];
  },

  [type.NEW_WORK_PLAN](state, notification) {
    state.newWorkPlan.unshift(notification);
  },
  [type.DELETE_WORK_PLAN](state, notification) {
    const index = state.newWorkPlan.findIndex(el => el._id === notification._id);
    if (index > -1)
      state.newWorkPlan.splice(index, 1);
  },

  [type.NEW_LEAD_ADD](state, lead) {
    const index = state.newLeads.findIndex(l => l._id === lead._id);
    if (index === -1) {
      state.newLeads.unshift(lead);
    }
  },
  [type.NEW_LEAD_SET](state, leads) {
    state.newLeads = leads;
  },
  [type.NEW_LEAD_DELETE](state, lead) {
    const index = state.newLeads.findIndex(l => l._id === lead._id);
    if (index > -1) {
      state.newLeads.splice(index, 1);
    }
  },
  [type.NEW_LEAD_CLEAR](state) {
    state.newLeads = [];
  },

  [type.MENTION_ADD](state, mention) {
    const index = state.mentions.findIndex(l => l._id === mention._id);
    if (index === -1) {
      state.mentions.unshift(mention);
    }
  },
  [type.MENTION_SET](state, mentions) {
    state.mentions = mentions;
  },
  [type.MENTION_DELETE](state, mention) {
    const index = state.mentions.findIndex(l => l._id === mention._id);
    if (index > -1) {
      state.mentions.splice(index, 1);
    }
  },
  [type.MENTION_CLEAR](state) {
    state.mentions = [];
  },

  [type.APPT_REMINDER_ADD](state, appt) {
    const index = state.apptReminders.findIndex(el => el._id === appt._id);
    if (index === -1) {
      state.apptReminders.unshift(appt);
    }
  },
  [type.APPT_REMINDER_SET](state, appts) {
    state.apptReminders = appts;
  },
  [type.APPT_REMINDER_DELETE](state, appt) {
    const index = state.apptReminders.findIndex(el => el._id === appt._id);
    if (index > -1) {
      state.apptReminders.splice(index, 1);
    }
  },
  [type.APPT_REMINDER_CLEAR](state) {
    state.apptReminders = [];
  },

  [type.FINANCE_APPLICATION_ADD](state, finance) {
    const index = state.financeApplications.findIndex(el => el._id === finance._id);
    if (index === -1) {
      state.financeApplications.unshift(finance);
    }
  },
  [type.FINANCE_APPLICATION_SET](state, finances) {
    state.financeApplications = finances;
  },
  [type.FINANCE_APPLICATION_DELETE](state, finance) {
    const index = state.financeApplications.findIndex(el => el._id === finance._id);
    if (index > -1) {
      state.financeApplications.splice(index, 1);
    }
  },
  [type.FINANCE_APPLICATION_CLEAR](state) {
    state.financeApplications = [];
  },

  [type.LOADING_ADD](state, value) {
    state.loading.unshift(value);
  },
  [type.LOADING_DELETE](state, value) {
    let index = state.loading.indexOf(value);
    if (index > -1) {
      state.loading.splice(index, 1)
    }
  },
  [type.LOADING_CLEAR](state) {
    state.loading = [];
  },

  [type.CALL_REQUEST_ADD](state, connection) {
    try {
      const index = state.callRequests.findIndex(c => c.parameters.CallSid === connection.parameters.CallSid);

      if (index > -1) {
        state.callRequests.splice(index, 1);
        state.callRequests.unshift(connection);
      } else {
        state.callRequests.unshift(connection);
      }
    } catch (e) {
      state.callRequests.unshift(connection);
    }
  },
  [type.CALL_REQUEST_SET](state, chatRequests) {
    state.callRequests = chatRequests;
  },
  [type.CALL_REQUEST_DELETE](state, sid) {
    let index = state.callRequests.findIndex(c => c.parameters.CallSid === sid);
    if (index > -1) {
      state.callRequests.splice(index, 1)
    }
  },
  [type.CALL_REQUEST_CLEAR](state) {
    state.callRequests = [];
  },

  [type.HOLD_CALL_REQUEST_ADD](state, connection) {
    try {
      const index = state.callRequestsHold.findIndex(c => c.parameters.CallSid === c.parameters.CallSid);
      if (index > -1) {
        state.callRequestsHold.splice(index, 1);
        state.callRequestsHold.unshift(connection);
      } else {
        state.callRequestsHold.unshift(connection);
      }
    } catch (e) {
      state.callRequestsHold.unshift(connection);
    }
  },
  [type.HOLD_CALL_REQUEST_DELETE](state, sid) {
    let index = state.callRequestsHold.findIndex(c => c.parameters.CallSid === sid);
    if (index > -1) {
      state.callRequestsHold.splice(index, 1)
    }
  },

  [type.CALL_QUEUE_ADD](state, queue) {
    const index = state.callQueues.findIndex(l => l._id === queue._id);
    if (index === -1) {
      state.callQueues.unshift(queue);
    }
  },

  [type.CALL_QUEUE_SHIFT](state, queueId) {
    const index = state.callQueues.findIndex(q => q._id === queueId);

    if (index > -1) {
      state.callQueues[index].Clients.shift();
    }
  },

  [type.CALL_QUEUE_UPDATE](state, queue) {
    let index = state.callQueues.findIndex(c => c._id === queue._id);
    if (index > -1) {
      state.callQueues.splice(index, 1, queue);
    } else {
      state.callQueues.push(queue);
    }
  },

  [type.CALL_QUEUE_SET](state, queuedCalls) {
    state.callQueues = queuedCalls;
  },

  [type.CALL_QUEUE_DELETE](state, id) {
    let index = state.callQueues.findIndex(c => c._id === id);
    if (index > -1) {
      state.callQueues.splice(index, 1)
    }
  },

  [type.CALL_QUEUE_CLEAR](state) {
    state.callQueues = [];
  },

  [type.SET_POLL_EMAIL](state, uid) {
    state.poll_email_uid = uid;
  },

  [type.COMPANY_SETTINGS_SET](state, companySettings) {
    state.companySettings = companySettings;
  },

  [type.SET_COMPANY_PERMISSIONS](state, permissions) {
    state.companyPermissions = permissions;
  },

  [type.CALL_START](state, data) {
    if (!state.onCall) {
      state.callTime = 0;
      state.onCall = data;
      increaseTime(state);
    }
  },
  [type.SET_CALL_TIME](state, data) {
    state.callTime = data;
  },

  [type.SET_CALL_SID](state, sid) {
    state.callSid = sid;
  },

  [type.CALL_END](state) {
    state.onCall = false;
    state.callTime = 0;
  },
  [type.SET_CALL_CONNECTION](state, connection) {
    state.callConnection = connection;
  },

  [type.CHAT_GROUP_ADD](state, group) {
    group.group.archived = false;
    group.group.sessionActivity = true;
    group.group.optOut = false;
    state.chatGroups.unshift(group);
  },
  [type.CHAT_GROUP_SET](state, groups) {
    state.chatGroups = groups;
  },
  [type.CHAT_GROUP_UPDATE](state, data) {
    if (state.chatGroups[data.index]) {
      state.chatGroups[data.index] = data.group;

      state.chatGroups.sort((a, b) => {
        const aDate = new Date(a.lastMessage.date);
        const bDate = new Date(b.lastMessage.date);
        if (aDate < bDate) return 1;
        if (aDate > bDate) return -1;
        return 0;
      });
    }
  },
  [type.CHAT_GROUP_SET_NOT_READ](state, data) {
    state.chatGroups[data.index].notRead = +data.value;

    state.chatGroups.sort((a, b) => {
      const aDate = new Date(a.lastMessage.date);
      const bDate = new Date(b.lastMessage.date);
      if (aDate < bDate) return 1;
      if (aDate > bDate) return -1;
      return 0;
    });
  },

  [type.CHAT_GROUP_DEL](state, id) {
    let index = state.chatGroups.findIndex(i => i.group._id === id);

    if (index > -1)
      state.chatGroups.splice(index, 1);
  },

  [type.CHAT_GROUP_SET_ONLINE](state, id) {
    let index = state.chatGroups.findIndex(i => i.group._id === id);

    if (index > -1) {
      state.chatGroups[index].group.online = true;
    }

  },
  [type.CHAT_GROUP_SET_OFFLINE](state, id) {

    let index = state.chatGroups.findIndex(i => i.group._id === id);

    if (index > -1) {
      state.chatGroups[index].group.online = false;
    }

  },
  [type.CHAT_GROUP_CLEAR](state) {
    state.chatGroups = [];
  },


  [type.ARCHIVED_CHAT_GROUP_ADD](state, group) {
    group.group.archived = true;
    state.archivedChatGroups.unshift(group);
  },
  [type.ARCHIVED_CHAT_GROUPS_SET](state, users) {
    state.archivedChatGroups = users;
  },
  [type.ARCHIVED_CHAT_GROUP_UPDATE](state, data) {
    if (state.archivedChatGroups[data.index]) {
      state.archivedChatGroups[data.index] = data.group;
    }
  },
  [type.ARCHIVED_CHAT_GROUP_SET_NOT_READ](state, data) {
    state.archivedChatGroups[data.index].notRead = +data.value;
  },
  [type.ARCHIVED_CHAT_GROUP_DEL](state, id) {
    let index = state.archivedChatGroups.findIndex(u => u.group._id === id);
    if (index > -1)
      state.archivedChatGroups.splice(index, 1);
  },
  [type.ARCHIVED_CHAT_GROUP_SET_ONLINE](state, id) {
    let index = state.archivedChatUsers.findIndex(u => u.group._id === id);

    if (index > -1) {
      const oldGroup = state.archivedChatGroups[index];
      oldGroup.group.status = "ONLINE";
      state.archivedChatGroups.splice(index, 1);
      state.archivedChatGroups.unshift(oldGroup);
    }

  },
  [type.ARCHIVED_CHAT_GROUP_SET_OFFLINE](state, id) {
    let index = state.archivedChatUsers.findIndex(u => u.group._id === id);

    if (index > -1) {
      const oldGroup = state.archivedChatGroups[index];
      oldGroup.group.status = "OFFLINE";
      state.archivedChatGroups.splice(index, 1);
      state.archivedChatGroups.unshift(oldGroup);
    }

  },
  [type.ARCHIVED_CHAT_GROUP_CLEAR](state) {
    state.archivedChatGroups = [];
  },

  [type.OPTOUT_CHAT_GROUP_ADD](state, group) {
    group.group.archived = false;
    group.group.optOut = true;
    state.optoutChatGroups.unshift(group);
  },
  [type.OPTOUT_CHAT_GROUP_DEL](state, id) {
    let index = state.optoutChatGroups.findIndex(u => u.group._id === id);
    if (index > -1)
      state.optoutChatGroups.splice(index, 1);
  },
  [type.OPTOUT_CHAT_GROUP_CLEAR](state) {
    state.optoutChatGroups = [];
  },
  [type.OPTOUT_CHAT_GROUP_SET_NOT_READ](state, data) {
    state.optoutChatGroups[data.index].notRead = +data.value;
  },

  [type.BOT_CHAT_GROUP_ADD](state, group) {
    state.botChatGroups.unshift(group);
  },
  [type.BOT_CHAT_GROUP_DEL](state, id) {
    let index = state.botChatGroups.findIndex(u => u.group._id === id);
    if (index > -1)
      state.botChatGroups.splice(index, 1);
  },
  [type.BOT_CHAT_GROUP_CLEAR](state) {
    state.botChatGroups = [];
  },
  [type.BOT_CHAT_GROUP_SET_NOT_READ](state, data) {
    state.botChatGroups[data.index].notRead = +data.value;
  },

  [type.SET_ROUTE_TITLE](state, title) {
    state.routeTitle = title;
  },

  [type.SET_UPLOADING](state, value) {
    state.uploading = value;
  },

  [type.SET_DOWNLOADING](state, value) {
    state.downloading = value;
  },

  [type.SET_UPLOAD_IMPORT_FILE_DATA](state, value) {
    state.uploadImportFileData = value;
  },

  [type.SET_UPLOAD_IMPORT_STEP](state, value) {
    state.uploadImportFileStep = value;
  },

  [type.SET_SETTINGS_LINKS](state, value) {
    state.settingsLinks = value;
    localStorage.setItem('settings_links', JSON.stringify(value));
  },

  [type.SET_CAR_PAGINATION_DETAILS](state, value) {
    state.carPaginationDetails = value;
    localStorage.setItem('car_pag_details', JSON.stringify(value));
  },

  [type.INTERNAL_CHAT_SET_VISIBLE](state, value) {
    state.isInternalChatVisible = value;
  },
  [type.INTERNAL_CHAT_SET_UNREADS](state, {dm, group}) {
    state.internalChatUnread = {total: dm + group, group, dm};
  },
  [type.INTERNAL_CHAT_INC_UNREAD](state, messageType = '') {
    const isValidType = ['dm', 'group'].includes(messageType);
    if (isValidType) {
      state.internalChatUnread[messageType] += 1;
      state.internalChatUnread.total += 1;
    }
  },
  [type.INTERNAL_CHAT_ADD_MESSAGE](state, message = {}) {
    const index = state.internalChatMessages.findIndex(el => el._id === message._id);
    if (index === -1) {
      state.internalChatMessages.push(message);
    }
  },
  [type.INTERNAL_CHAT_SET_MESSAGES](state, messages = []) {
    state.internalChatMessages = messages;
  },

  [type.USER_LOCKED_CUSTOMERS_SET](state, lockedCustomers) {
    state.userLockedCustomers = lockedCustomers;
  },
  [type.USER_LOCKED_CUSTOMERS_CLEAN](state) {
    state.userLockedCustomers = [];
  },
};