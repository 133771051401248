import {state} from "./state";

export const getters = {
  isLoggedIn(state) {
    return state.isLoggedIn
  },

  isUserIdle(state) {
    return state.userIsIdle;
  },

  hasCompanyPermissions: (state) => (permissions) => {
    const [module, feature] = permissions.split('.');

    const user = state.user;
    if (user && (user.permissions.isRoot || user.permissions.isSupport)) {
      return true;
    }

    const companyPermissions = state.companyPermissions;
    if (!companyPermissions) {
      return false;
    }

    if (module && module.length > 0) {
      if (feature && feature.length > 0) {
        return !!(companyPermissions.permissions[module] && companyPermissions.permissions[module].features[feature]);

      } else {
        return !!(companyPermissions.permissions[module] && companyPermissions.permissions[module].enabled);
      }

    } else {
      return false;
    }
  },

  isLoading() {
    return (value) => {
      return state.loading.indexOf(value) > -1;
    }
  },

  user(state) {
    return state.user;
  },

  isMaxUserLockedCustomers: (state) => (customerId) => {
    const {userLockedCustomers, user} = state;
    const {permissions: {isAdmin, isBDC}} = user;
    const {access: {listing: {maxLockedCustomers}}} = user;

    const isBDCAgent = !isAdmin && isBDC;
    if (!isBDCAgent) {
      return false;
    }

    if (userLockedCustomers.length > maxLockedCustomers) {
      return userLockedCustomers.slice(maxLockedCustomers)
          .findIndex(customer => customer._id.toString() === customerId.toString()) > -1;
    } else {
      return false;
    }
  },

  onCall(state) {
    return state.onCall;
  },

  callTime(state) {
    return state.callTime;
  },

  callConnection(state) {
    return state.callConnection;
  },

  callRequests(state) {
    return state.callRequests;
  },

  callRequestsHold(state) {
    return state.callRequestsHold;
  },

  callQueues(state) {
    return state.callQueues;
  },

  companySettings(state) {
    return state.companySettings;
  },

  unreadNotifications(state) {
    return state.unreadNotifications;
  },

  mentions(state) {
    return state.mentions;
  },

  chatRequests(state) {
    return state.chatRequests;
  },

  chatBotRequests(state) {
    return state.chatBotRequests;
  },

  smsRequests(state) {
    return state.smsRequests;
  },

  transferRequests(state) {
    return state.transferRequests;
  },

  customerNotifications(state) {
    return state.customerNotifications;
  },

  newLeads(state) {
    return state.newLeads;
  },

  apptReminders(state) {
    return state.apptReminders;
  },

  financeApplications(state) {
    return state.financeApplications;
  },

  newWorkPlan(state) {
    return state.newWorkPlan;
  },

  callSid(state) {
    return state.callSid;
  },

  pollEmailUid(state) {
    return state.poll_email_uid;
  },

  chatGroups(state) {
    return state.chatGroups;
  },

  botChatGroups(state) {
    return state.botChatGroups;
  },

  archivedChatGroups(state) {
    return state.archivedChatGroups;
  },

  optoutChatGroups(state) {
    return state.optoutChatGroups;
  },

  routeTitle(state) {
    return state.routeTitle;
  },

  getUploading() {
    return state.uploading;
  },

  getDownloading() {
    return state.downloading;
  },

  getUploadImportFileData() {
    return state.uploadImportFileData
  },

  getUploadImportFileStep() {
    return state.uploadImportFileStep
  },

  getSettingsLinks() {
    return state.settingsLinks;
  },

  getCarPaginationDetails() {
    return state.carPaginationDetails;
  },

  isInternalChatVisible(state) {
    return state.isInternalChatVisible;
  },

  getInternalChatUnread(state) {
    return state.internalChatUnread;
  },

  getInternalChatMessages(state) {
    return state.internalChatMessages;
  },
};